<template>
  <div>
    <div class="brdr-bottom-1 brdr-cl-primary pb60">
      <h3 class="cl-accent ml30 mt50 summary-title">
        Order Summary
      </h3>
      <product v-for="product in getCart" :key="product.item.id" :product="product" @remove="remove"/>
      <div class="row pt15 pb20 pl30 pr55 " v-if="shippingMethodPrice">
        <div class="col-xs cl-accent">
          Shipping
        </div>
        <div class="col-xs align-right cl-accent h4">
          {{ shippingMethodPrice | price }}
        </div>
      </div>
      <div v-if="cart && cart.length" class="checkout bg-cl-secondary pt10 serif cl-accent">
        <div class="row pt15 pb20 pl30 pr55 ">
          <div class="col-xs cl-accent">
            Total
          </div>
          <div class="col-xs align-right cl-accent h4">
            {{ getCartTotal | price }}
          </div>
        </div>
      </div>
    </div>
    <div class="py50 px25">
      <h4 class="h3 m0">
        Secure Payment
      </h4>
      <p class="cl-primary lh20">
        Your payment information is processed by Braintree, a PayPal company. We do not store any payment information on couturme.com site.
      </p>
      <h4 class="h3 mb0">
        Shipping
      </h4>
      <p class="cl-primary lh20">
        Free delivery and Free returns within the US. $25 shipping to all other coutries.
      </p>
      <h4 class="h3 mb0">
        Returns
      </h4>
      <p class="cl-primary lh20">
        Return for a full refund within 30 days, using our free return service. Click
        <router-link to="/faq#returns">here</router-link> for more info.
      </p>
    </div>
  </div>
</template>

<script>
import Product from './Product'
import { mapState, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Product
  },
  computed: {
    ...mapState('cart', ['cart']),
    ...mapGetters('cart', ['getCartTotal', 'getCart', 'shippingMethodPrice'])
  },
  methods: {
    ...mapMutations('cart', ['remove'])
  }
}
</script>

<style lang="scss" scoped>
  .summary-title {
    @media (max-width: 767px) {
      margin-left: 0;
    }
  }
</style>
