<template>
  <div class="payment pt20">
    <div class="row pl20">
      <div class="col-xs-1 col-sm-2 col-md-1">
        <div
          class="number-circle lh35 cl-white brdr-circle align-center weight-700"
          :class="{ 'bg-cl-th-accent' : isActive || isFilled, 'bg-cl-tertiary' : !isFilled && !isActive }"
        >
          3
        </div>
      </div>
      <div class="col-xs-11 col-sm-9 col-md-11">
        <div class="row mb15">
          <div class="col-xs-12 col-md-7" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <h3 class="m0 mb5">
              Payment
            </h3>
          </div>
          <div class="col-xs-12 col-md-5 pr30">
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-tertiary flex" @click.prevent="edit">
                <span class="pr5">
                  Edit payment
                </span>
                <i class="material-icons cl-tertiary">edit</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl20" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-11 col-sm-9 col-md-10">
        <div class="row" v-if="isActive">
          <base-checkbox
            class="col-xs-12 mb15"
            id="sendToShippingAddressCheckbox"
            v-model="sendToShippingAddress"
            @click="useShippingAddress"
          >
            Copy address data from shipping
          </base-checkbox>

          <base-checkbox
            v-if="hasBillingData()"
            class="col-xs-12 mb15"
            id="sendToBillingAddressCheckbox"
            v-model="sendToBillingAddress"
            @click="useBillingAddress"
          >
            Use my billing data
          </base-checkbox>

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="first-name"
            :placeholder="'First name *'"
            v-model.trim="firstName"
            @blur="$v.payment.firstName.$touch()"
            autocomplete="given-name"
            :validations="[
              {
                condition: $v.payment.firstName.$error && !$v.payment.firstName.required,
                text: 'Field is required'
              },
              {
                condition: !$v.payment.firstName.minLength,
                text: 'Name must have at least 3 letters.'
              }
            ]"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="last-name"
            :placeholder="'Last name *'"
            v-model.trim="lastName"
            @blur="$v.payment.lastName.$touch()"
            autocomplete="family-name"
            :validation="{
              condition: $v.payment.lastName.$error && !$v.payment.lastName.required,
              text: 'Field is required'
            }"
          />

          <base-input
            class="col-xs-12 mb25"
            type="text"
            name="street-address"
            :placeholder="'Street name *'"
            v-model.trim="streetAddress"
            @blur="$v.payment.streetAddress.$touch()"
            autocomplete="address-line1"
            :validation="{
              condition: $v.payment.streetAddress.$error && !$v.payment.streetAddress.required,
              text: 'Field is required'
            }"
          />

          <base-input
            class="col-xs-12 mb25"
            type="text"
            name="apartment-number"
            :placeholder="'House/Apartment number *'"
            v-model.trim="apartmentNumber"
            autocomplete="address-line2"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="city"
            :placeholder="'City *'"
            v-model.trim="city"
            @blur="$v.payment.city.$touch()"
            autocomplete="address-level2"
            :validation="{
              condition: $v.payment.city.$error && !$v.payment.city.required,
              text: 'Field is required'
            }"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="state"
            :placeholder="'State / Province'"
            v-model.trim="state"
            autocomplete="address-level1"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="zip-code"
            :placeholder="'Zip code *'"
            v-model.trim="zipCode"
            @blur="$v.payment.zipCode.$touch()"
            autocomplete="postal-code"
            :validations="[
              {
                condition: $v.payment.zipCode.$error && !$v.payment.zipCode.required,
                text: 'Field is required'
              },
              {
                condition: !$v.payment.zipCode.minLength,
                text: 'Zip code must have at least 3 letters.'
              }
            ]"
          />

          <base-select
            class="col-xs-12 col-sm-6 mb25"
            name="countries"
            :options="countryOptions"
            :selected="payment.country"
            :placeholder="'Country *'"
            :validations="[
              {
                condition: $v.payment.country.$error && !$v.payment.country.required,
                text: 'Field is required'
              }
            ]"
            v-model="country"
            autocomplete="country-name"
            @blur="$v.payment.country.$touch()"
            @change="$v.payment.country.$touch()"
          />

          <base-input
            class="col-xs-12 mb25"
            type="text"
            name="phone-number"
            :placeholder="'Phone Number'"
            v-model.trim="phoneNumber"
            autocomplete="tel"
            :validation="{
              condition: $v.payment.phoneNumber.$error && !$v.payment.phoneNumber.required,
              text: 'Field is required'
            }"
            @blur="$v.payment.phoneNumber.$touch()"
            @input="$v.payment.phoneNumber.$touch()"
          />

          <!--<base-checkbox
            class="col-xs-12 mb15"
            id="generateInvoiceCheckbox"
            v-model="generateInvoice"
            @click="useGenerateInvoice"
          >
            I want to generate an invoice for the company
          </base-checkbox>-->

          <template v-if="generateInvoice">
            <base-input
              class="col-xs-12 mb25"
              type="text"
              name="company-name"
              :placeholder="'Company name *'"
              v-model.trim="company"
              @blur="$v.payment.company.$touch()"
              autocomplete="organization"
              :validation="{
                condition: $v.payment.company.$error && !$v.payment.company.required,
                text: 'Field is required'
              }"
            />

            <base-input
              class="col-xs-12 mb25"
              type="text"
              name="tax-id"
              :placeholder="'Tax identification number *'"
              v-model.trim="taxId"
              @blur="$v.payment.taxId.$touch()"
              autocomplete="tax-id"
              :validations="[
                {
                  condition: $v.payment.taxId.$error && !$v.payment.taxId.required,
                  text: 'Field is required'
                },
                {
                  condition: !$v.payment.taxId.minLength,
                  text: 'Tax identification number must have at least 3 letters.'
                }
              ]"
            />

            <!--<div class="col-xs-12 mb25">
              <label class="fs16">
                We will send you the invoice to given e-mail address
              </label>
            </div>-->
          </template><!--

          <div class="col-xs-12">
            <h4>
              Payment method
            </h4>
          </div>
          <div v-for="(method, index) in paymentMethods" :key="index" class="col-md-6">
            <label class="radioStyled"> {{ method.title ? method.title : method.name }}
              <input
                type="radio"
                :value="method.code"
                name="payment-method"
                v-model="paymentMethod"
                @change="$v.payment.paymentMethod.$touch(); changePaymentMethod();"
              >
              <span class="checkmark" />
            </label>
          </div>
          <span class="validation-error" v-if="!$v.payment.paymentMethod.required">Field is required</span>-->
        </div>
      </div>
    </div>
    <div class="row" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11">
        <div class="row">
          <div class="col-xs-12 col-md-8 px20 my30">
            <button-full
              @click.native="sendDataToCheckout"
              data-testid="paymentSubmit"
              :disabled="$v.payment.$invalid"
            >
              Go review the order
            </button-full>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl20" v-if="!isActive && isFilled">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11">
        <div class="row fs16 mb35">
          <div class="col-xs-12 h4">
            <p>
              {{ payment.firstName }} {{ payment.lastName }}
            </p>
            <p>
              {{ payment.streetAddress }} {{ payment.apartmentNumber }}
            </p>
            <p>
              {{ payment.city }} {{ payment.zipCode }}
            </p>
            <p>
              <span v-if="payment.state">{{ payment.state }}, </span>
              <span>{{ getCountryName() }}</span>
            </p>
            <div v-if="payment.phoneNumber">
              <span class="pr15">{{ payment.phoneNumber }}</span>
              <tooltip>Phone number may be needed by carrier</tooltip>
            </div>
            <p v-if="generateInvoice">
              {{ payment.company }} {{ payment.taxId }}
            </p>
            <!--<div class="col-xs-12">
              <h4>Payment method</h4>
            </div>
            <div class="col-md-6 mb15">
              <label class="radioStyled"> {{ getPaymentMethod().title }}
                <input type="radio" value="" checked disabled name="chosen-payment-method">
                <span class="checkmark" />
              </label>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import Tooltip from '@/components/Tooltip'
import Countries from '../../../resource/i18n/countries.json'
import { mapState } from 'vuex'
import { createHelpers } from 'vuex-map-fields'

// `fooModule` is the name of the Vuex module.
const { mapFields } = createHelpers({
  getterType: 'cart/getField',
  mutationType: 'cart/updateField'
})

export default {
  components: {
    Tooltip
  },
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isFilled: false,
      countries: Countries,
      generateInvoice: false,
      sendToShippingAddress: false,
      sendToBillingAddress: false
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    ...mapState('cart', ['paymentMethods']),
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    ...mapFields([
      'paymentDetails.firstName',
      'paymentDetails.lastName',
      'paymentDetails.streetAddress',
      'paymentDetails.apartmentNumber',
      'paymentDetails.city',
      'paymentDetails.state',
      'paymentDetails.zipCode',
      'paymentDetails.country',
      'paymentDetails.phoneNumber',
      'paymentDetails.company',
      'paymentDetails.taxId',
      'paymentDetails.paymentMethod'
    ]),
    payment: {
      get () {
        return this.$store.state.cart.paymentDetails
      },
      set (val) {
        this.$store.commit('cart/setPaymentDetails', val)
      }
    }
  },
  validations () {
    if (!this.generateInvoice) {
      return {
        payment: {
          firstName: {
            required,
            minLength: minLength(3)
          },
          lastName: {
            required
          },
          country: {
            required
          },
          streetAddress: {
            required
          },
          zipCode: {
            required,
            minLength: minLength(3)
          },
          city: {
            required
          },
          phoneNumber: {
            required
          }
        }
      }
    } else {
      return {
        payment: {
          firstName: {
            required,
            minLength: minLength(3)
          },
          lastName: {
            required
          },
          company: {
            required
          },
          taxId: {
            required,
            minLength: minLength(3)
          },
          country: {
            required
          },
          streetAddress: {
            required
          },
          zipCode: {
            required,
            minLength: minLength(4)
          },
          city: {
            required
          },
          phoneNumber: {
            required
          }
        }
      }
    }
  },
  mounted () {
    if (this.payment.firstName.length === 0) {
      this.initializeBillingAddress()
    } else {
      if (this.payment.company) {
        this.generateInvoice = true
      }
    }
    this.changePaymentMethod()
  },
  methods: {
    sendDataToCheckout () {
      this.$bus.$emit('checkout-after-paymentDetails', this.payment, this.$v)
      this.isFilled = true
    },
    edit () {
      if (this.isFilled) {
        this.$bus.$emit('checkout-before-edit', 'payment')
        this.isFilled = false
      }
    },
    hasBillingData () {
      if (this.currentUser) {
        if (this.currentUser.hasOwnProperty('default_billing')) {
          return true
        }
      }
      return false
    },
    initializeBillingAddress () {
      let initialized = false
      if (this.currentUser) {
        if (this.currentUser.hasOwnProperty('default_billing')) {
          let id = this.currentUser.default_billing
          let addresses = this.currentUser.addresses
          for (let i = 0; i < addresses.length; i++) {
            if (addresses[i].id === Number(id)) {
              this.payment = {
                firstName: addresses[i].firstname,
                lastName: addresses[i].lastname,
                company: addresses[i].company,
                country: addresses[i].country_id,
                state: addresses[i].region.region ? addresses[i].region.region : '',
                city: addresses[i].city,
                streetAddress: addresses[i].street[0],
                apartmentNumber: addresses[i].street[1],
                zipCode: addresses[i].postcode,
                taxId: addresses[i].vat_id,
                phoneNumber: addresses[i].telephone,
                paymentMethod: this.paymentMethods[0].code
              }
              this.generateInvoice = true
              this.sendToBillingAddress = true
              initialized = true
            }
          }
        }
      }
      if (!initialized) {
        this.payment = {
          firstName: '',
          lastName: '',
          company: '',
          country: '',
          state: '',
          city: '',
          streetAddress: '',
          apartmentNumber: '',
          postcode: '',
          zipCode: '',
          phoneNumber: '',
          taxId: '',
          paymentMethod: this.paymentMethods[0].code
        }
      }
    },
    useShippingAddress () {
      this.sendToShippingAddress = !this.sendToShippingAddress
      if (this.sendToShippingAddress) {
        let shippingDetails = this.$store.state.cart.shippingDetails
        this.payment = {
          firstName: shippingDetails.firstName,
          lastName: shippingDetails.lastName,
          country: shippingDetails.country,
          state: shippingDetails.state,
          city: shippingDetails.city,
          streetAddress: shippingDetails.streetAddress,
          apartmentNumber: shippingDetails.apartmentNumber,
          zipCode: shippingDetails.zipCode,
          phoneNumber: shippingDetails.phoneNumber,
          paymentMethod: this.paymentMethods[0].code
        }
        this.sendToBillingAddress = false
        this.generateInvoice = false
      } else {
        this.payment = this.$store.state.cart.paymentDetails
        this.generateInvoice = false
      }
    },
    useBillingAddress () {
      this.sendToBillingAddress = !this.sendToBillingAddress
      if (this.sendToBillingAddress) {
        let id = this.currentUser.default_billing
        let addresses = this.currentUser.addresses
        for (let i = 0; i < addresses.length; i++) {
          if (addresses[i].id === Number(id)) {
            this.payment = {
              firstName: addresses[i].firstname,
              lastName: addresses[i].lastname,
              company: addresses[i].company,
              country: addresses[i].country_id,
              state: addresses[i].region.region ? addresses[i].region.region : '',
              city: addresses[i].city,
              streetAddress: addresses[i].street[0],
              apartmentNumber: addresses[i].street[1],
              zipCode: addresses[i].postcode,
              taxId: addresses[i].vat_id,
              phoneNumber: addresses[i].telephone,
              paymentMethod: this.paymentMethods[0].code
            }
            this.generateInvoice = true
          }
        }
        this.sendToShippingAddress = false
      } else {
        this.payment = this.$store.state.cart.paymentDetails
        this.generateInvoice = false
      }
    },
    useGenerateInvoice () {
      this.generateInvoice = !this.generateInvoice
      if (!this.generateInvoice) {
        this.payment.company = ''
        this.payment.taxId = ''
      }
    },
    getCountryName () {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].code === this.payment.country) {
          return this.countries[i].name
        }
      }
      return ''
    },
    getPaymentMethod () {
      for (let i = 0; i < this.paymentMethods.length; i++) {
        if (this.paymentMethods[i].code === this.payment.paymentMethod) {
          return {
            title: this.paymentMethods[i].title ? this.paymentMethods[i].title : this.paymentMethods[i].name
          }
        }
      }
      return {
        name: ''
      }
    },
    notInMethods (method) {
      let availableMethods = this.paymentMethods
      if (availableMethods.find(item => item.code === method)) {
        return false
      }
      return true
    },
    changePaymentMethod () {
      // reset the additional payment method component container if exists.
      if (document.getElementById('checkout-order-review-additional-container')) {
        document.getElementById('checkout-order-review-additional-container').innerHTML = '<div id="checkout-order-review-additional">&nbsp;</div>' // reset
      }

      // Let anyone listening know that we've changed payment method, usually a payment extension.
      this.$bus.$emit('checkout-payment-method-changed', this.payment.paymentMethod)
    }
  }
}
</script>
