<template>
  <div class="personal-details">
    <div class="row pl20">
      <div class="col-xs-1 col-sm-2 col-md-1">
        <div
          class="number-circle lh35 cl-white brdr-circle align-center weight-700"
          :class="{ 'bg-cl-th-accent' : isActive || isFilled, 'bg-cl-tertiary' : !isFilled && !isActive }"
        >
          1
        </div>
      </div>
      <div class="col-xs-11 col-sm-9 col-md-11">
        <div class="row mb15">
          <div class="col-xs-12 col-md-7" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <h3 class="m0 mb5">
              Personal Details
            </h3>
          </div>
          <div class="col-xs-12 col-md-5 pr30">
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-tertiary flex" @click.prevent="edit">
                <span class="pr5">
                  Edit personal details
                </span>
                <i class="material-icons cl-tertiary">edit</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl20" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-11 col-sm-9 col-md-10">
        <div class="row w-100">
          <base-input
            class="col-xs-12 col-md-6 mb25"
            type="text"
            :autofocus="true"
            name="first-name"
            :placeholder="'First name *'"
            v-model.trim="firstName"
            @blur="$v.personalDetails.firstName.$touch()"
            autocomplete="given-name"
            :validations="[
              {
                condition: $v.personalDetails.firstName.$error && !$v.personalDetails.firstName.required,
                text: 'Field is required'
              },
              {
                condition: !$v.personalDetails.firstName.minLength,
                text: 'Name must have at least 3 letters.'
              }
            ]"
          />

          <base-input
            class="col-xs-12 col-md-6 mb25"
            type="text"
            name="last-name"
            :placeholder="'Last name *'"
            v-model.trim="lastName"
            @blur="$v.personalDetails.lastName.$touch()"
            autocomplete="family-name"
            :validation="{
              condition: $v.personalDetails.lastName.$error && !$v.personalDetails.lastName.required,
              text: 'Field is required'
            }"
          />

          <base-input
            class="col-xs-12 mb25 px8"
            type="email"
            name="email-address"
            :placeholder="'Email address *'"
            v-model="emailAddress"
            @blur="$v.personalDetails.emailAddress.$touch()"
            autocomplete="email"
            @keyup.enter="sendDataToCheckout"
            :validations="[
              {
                condition: $v.personalDetails.emailAddress.$error && !$v.personalDetails.emailAddress.required,
                text: 'Field is required'
              },
              {
                condition: !$v.personalDetails.emailAddress.email && $v.personalDetails.emailAddress.$error,
                text: 'Please provide valid e-mail address.'
              }
            ]"
          />

          <!--<base-checkbox
            v-if="!currentUser"
            class="col-xs-12 mb15"
            id="createAccountCheckbox"
            @click="createAccount = !createAccount"
            v-model="createAccount"
          >
            I want to create an account
          </base-checkbox>-->

          <!--<template v-if="createAccount && !currentUser">
            <base-input
              class="col-xs-12 mb25 mt10"
              type="password"
              name="password"
              ref="password"
              :placeholder="'Password *'"
              v-model="password"
              @blur="$v.password.$touch()"
              autocomplete="new-password"
              :validation="{
                condition: $v.password.$error && !$v.password.required,
                text: 'Field is required.'
              }"
            />

            <base-input
              class="col-xs-12 mb25"
              type="password"
              name="password-confirm"
              :placeholder="'Repeat password *'"
              v-model="rPassword"
              autocomplete="new-password"
              :validations="[
                {
                  condition: $v.rPassword.$error && !$v.rPassword.required,
                  text: 'Field is required.'
                },
                {
                  condition:!$v.rPassword.sameAsPassword,
                  text: 'Passwords must be identical.'
                }
              ]"
            />

            <base-checkbox
              class="col-xs-12 mb15"
              id="acceptConditions"
              @click="acceptConditions = !acceptConditions"
              @blur="$v.acceptConditions.$touch()"
              v-model="acceptConditions"
              :validation="{
                condition: !$v.acceptConditions.required && $v.acceptConditions.$error,
                text: 'You must accept the terms and conditions.'
              }"
            >
              I accept
              <span
                class="link pointer"
                @click.prevent="$bus.$emit('modal-toggle', 'modal-terms')"
              >
                Terms and conditions
              </span>*
            </base-checkbox>
          </template>-->
        </div>
      </div>
    </div>
    <div class="row" v-show="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-11 col-sm-9 col-md-10">
        <div class="row my30">
          <div class="col-xs-12 col-md-7 px20 button-container">
            <button-full
              data-testid="personalDetailsSubmit"
              @click.native="sendDataToCheckout"
              :disabled="createAccount ? $v.$invalid : $v.personalDetails.$invalid"
            >
              Continue to shipping
            </button-full>
          </div>
          <!--<div
            class="col-xs-12 col-md-5 center-xs end-md"
            v-if="!currentUser"
          >
            <p class="h4 cl-accent">
              or
              <span
                class="link pointer"
                @click.prevent="gotoAccount"
              >
                login to your account
              </span>
            </p>
          </div>-->
        </div>
      </div>
    </div>
    <div class="row pl20" v-if="!isActive && isFilled">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11">
        <div class="row fs16 mb35">
          <div class="col-xs-12 h4">
            <p>
              {{ personalDetails.firstName }} {{ personalDetails.lastName }}
            </p>
            <div>
              <span class="pr15">{{ personalDetails.emailAddress }}</span>
              <tooltip>We will send you details regarding the order</tooltip>
            </div>
            <template v-if="createAccount && !currentUser">
              <base-checkbox
                class="mt25"
                id="createAccountCheckboxInfo"
                v-model="createAccount"
                disabled
              >
                Create a new account
              </base-checkbox>
              <p class="h5 cl-tertiary">
                The new account will be created with the purchase. You will receive details on e-mail.
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import { createHelpers } from 'vuex-map-fields'
import Tooltip from '@/components/Tooltip'
import { EVENT_USER_PROFILE_LOADED } from '@/store/modules/user/store'

// `fooModule` is the name of the Vuex module.
const { mapFields } = createHelpers({
  getterType: 'cart/getField',
  mutationType: 'cart/updateField'
})

export default {
  components: {
    Tooltip
  },
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    focusedField: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {
      isFilled: false,
      // personalDetails: this.$store.state.checkout.personalDetails,
      acceptConditions: false,
      rPassword: '',
      isValidationError: false
    }
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    ...mapState('cart', ['personalDetails']),
    ...mapFields([
      'personalDetails.firstName',
      'personalDetails.lastName',
      'personalDetails.emailAddress',
      'personalDetails.password',
      'personalDetails.createAccount'
    ])
  },
  validations: {
    personalDetails: {
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required
      },
      emailAddress: {
        required,
        email
      }
    },
    password: {
      required
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
    acceptConditions: {
      required
    }
  },
  methods: {
    onLoggedIn (receivedData) {
      this.firstName = receivedData.firstname
      this.lastName = receivedData.lastname
      this.emailAddress = receivedData.email
    },
    sendDataToCheckout () {
      if (this.createAccount) {
        this.password = this.password
        this.createAccount = true
      } else {
        this.createAccount = false
      }
      this.$bus.$emit('checkout-after-personalDetails', this.personalDetails, this.$v)
      this.isFilled = true
      this.isValidationError = false
    },
    edit () {
      if (this.isFilled) {
        this.$bus.$emit('checkout-before-edit', 'personalDetails')
        this.isFilled = false
      }
    },
    gotoAccount () {
      this.$bus.$emit('modal-show', 'modal-signup')
    }
  },
  updated () {
    // Perform focusing on a field, name of which is passed through 'focusedField' prop
    if (this.focusedField && !this.isValidationError) {
      if (this.focusedField === 'password') {
        this.isValidationError = true
        this.password = ''
        this.rPassword = ''
        this.$refs['password'].setFocus('password')
      }
    }
  },
  beforeMount () {
    this.$bus.$on(EVENT_USER_PROFILE_LOADED, this.onLoggedIn)
    if (this.$store.state.user.current) {
      this.onLoggedIn(this.$store.state.user.current)
    }
  },
  destroyed () {
    this.$bus.$off(EVENT_USER_PROFILE_LOADED, this.onLoggedIn)
  }
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: underline;
}

.login-prompt {
  @media (min-width: 1200px) {
    margin-top: 30px;
  }
}
</style>
