<template>
  <modal name="payment" width="600" height="auto" @before-open="handleOpen">
    <div class="modal-window__close-button" @click="close" />
    <div class="modal-window__caption -draggable">
      <md-icon>lock_outline</md-icon> Secure Payment
    </div>
    <div class="modal-window__content">
      <div ref="dropInPlace" />
    </div>

    <div class="modal-window__actions">
      <button-full class="mb20 mt10" @click="submitPayment" :disabled="!canSubmit">
        Apply
      </button-full>
    </div>
  </modal>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      agreed: true,
      authToken: '',
      dropInInstance: null,
      paymentIsValid: false,
      amount: 14,
      errors: []
    }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    canSubmit () {
      return this.paymentIsValid
    }
  },
  mounted () {
    this.$bus.$on('order-after-placed', this.close)
  },
  beforeDestroy () {
    this.$bus.$off('order-after-placed', this.close)
  },
  methods: {
    ...mapActions('questionnaire', ['obtainToken']),
    close () {
      this.$modal.hide('payment')
    },
    handleOpen (e) {
      if (e && e.params) {
        this.amount = e.params.amount || 0
      }
      this.obtainPaymentToken()
    },
    submitPayment () {
      if (this.canSubmit) {
        this.dropInInstance.requestPaymentMethod()
          .then((payload) => {
            this.errors = []

            // Send device data and nonce to your server
            // deviceDataInput.value = payload.deviceData
            // nonceInput.value = payload.nonce;
            this.$bus.$emit('checkout-do-placeOrder', payload)
          })
      }
    },
    obtainPaymentToken () {
      this.errors = []
      this.paymentIsValid = false
      this.agreed = false
      // trackEvent('funnel', 'transaction-start')
      return this.obtainToken()
        .then((clientToken) => {
          return import(/* webpackChunkName: "braintree-web-drop-in" */'braintree-web-drop-in')
            .then(({ create }) => {
              this.authToken = clientToken
              return create({
                authorization: clientToken,
                container: this.$refs.dropInPlace,
                paypal: {
                  flow: 'checkout',
                  amount: +this.amount.toFixed(2),
                  currency: 'USD',
                  buttonStyle: {
                    color: 'blue',
                    shape: 'rect',
                    size: 'medium'
                  }
                },
                venmo: {
                  allowNewBrowserTab: false
                }
              })
            })
        })
        .then(instance => {
          this.dropInInstance = instance
          this.listenForDropInEvents()
        })
    },
    listenForDropInEvents () {
      this.dropInInstance.on('paymentMethodRequestable', () => {
        this.errors = []
        this.paymentIsValid = true
      })
      this.dropInInstance.on('noPaymentMethodRequestable', () => {
        this.paymentIsValid = false
      })
    }
  }
}
</script>
<style>

</style>
