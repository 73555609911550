<template>
  <div class="image"
       :style="{ background: loaderBackground, ...sizeStyle }"
  >
    <img :src="modelImageSrc" alt="" :class="{'-loading': paintColor && !clothImage}">
    <img class="loading-image" src="../../../../assets/loading.svg" alt="" v-if="loader && paintColor && !clothImage">
    <img :src="image.url" alt="" v-for="image in item[sizeType]" v-if="!paintColor && !clothImage" :key="image.url">
    <img :src="clothImage" alt="" v-if="clothImage">
  </div>
</template>
<script>
import { combineCloth, hexToRgb } from '../../../../services/color'
import { defaultModelPicture } from '../../../../utils'

export default {
  name: 'OfferThumb',
  props: {
    model: {
      type: Object,
      default: () => ({
        'Main Image': []
      })
    },
    sizeType: {
      type: String,
      default: 'thumbs'
    },
    item: {
      type: Object,
      default: () => ({
        images: [],
        thumbs: [],
        paintColor: null,
        id: '',
        name: ''
      })
    },
    loader: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 210
    },
    height: {
      type: [Number, String],
      default: 300
    }
  },
  data () {
    return {
      clothImage: '',
      sizes: {
        thumbs: {
          width: 512,
          height: 723
        },
        images: {
          width: 1417,
          height: 2000
        }
      }
    }
  },
  mounted () {
    const color = this.paintColor
    if (color) {
      combineCloth(this.item[this.sizeType], this.sizes[this.sizeType].width, this.sizes[this.sizeType].height, color).then(canvas => {
        this.clothImage = canvas.toDataURL('image/png')
      }).catch(err => console.log(err, err.message))
    }
  },
  computed: {
    paintColor () {
      return this.item.paintColor && this.item.paintColor.color ? hexToRgb(this.item.paintColor.color) : null
    },
    paintColorHex () {
      return this.item.paintColor && this.item.paintColor.color ? this.item.paintColor.color : null
    },
    modelImageSrc () {
      if (this.item.model && this.item.model.front && this.item.model.front.mainImage) {
        return this.item.model.front.mainImage
      }
      if (this.model && this.model['Main Image'] && this.model['Main Image'][0]) {
        return this.model['Main Image'][0].url
      }
      return defaultModelPicture('front', this.$store.getters['questionnaire/answerByQuestionName']('question_model'))
    },
    modelImageBackSrc () {
      if (this.item.model && this.item.model.back && this.item.model.back.mainImage) {
        return this.item.model.back.mainImage
      }
      const model = this.offers.modelBack
      if (model && model['Main Image'] && model['Main Image'][0]) {
        return model['Main Image'][0].url
      }
      return defaultModelPicture('back', this.$store.getters['questionnaire/answerByQuestionName']('question_model'))
    },
    loaderBackground () {
      return this.loader && this.paintColor && !this.clothImage ? `radial-gradient(40% 50%, ${this.paintColorHex}, white)` : null
    },
    sizeStyle () {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .image {
    position: relative;
    img {
      &.-loading {
        opacity: 0;
      }
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-width: 100%;
      &.loading-image {
        transform: translate(0, -50%);
        top: 50%;
      }
    }
  }
</style>
