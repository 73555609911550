<template>
  <div class="row p25 between-xs">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12 col-md-2">
          <renderer size-type="thumbs" :item="product.item" width="75" height="100"/>
        </div>
        <div class="col-xs-12 col-md-7 pb15">
          <div class="mb15">
            <div class="h5 weight-400 cl-accent serif">
              {{ product.item.name | htmlDecode }}
            </div>
            <div class="error" v-if="product.errors && Object.keys(product.errors).length > 0">
              {{ product.errors | formatProductMessages }}
            </div>
            <!--<div class="h5 cl-tertiary pt5">
              {{ product.item.id }}
            </div>-->
            <div class="h6 cl-bg-tertiary pt5 options" v-if="product.totals && product.totals.options">
              <div v-for="opt in product.totals.options" :key="opt.label">
                <span class="opn">{{ opt.label }}: </span>
                <span class="opv" v-html="opt.value" />
              </div>
            </div>
            <div class="h6 cl-bg-tertiary pt5 options" v-else-if="product.options">
              <div v-for="opt in product.options" :key="opt.label">
                <span class="opn">{{ opt.label }}: </span>
                <span class="opv" v-html="opt.value" />
              </div>
            </div>
          </div>
          <div>
            <div>
              <span class="h5 cl-secondary">
                Qty
                <span class="weight-700">
                  {{ product.qty }}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-md-2 serif">
          <div v-if="product.price">
            <span class="h4">{{ product.price | price }}</span>
          </div>
        </div>
        <div class="col-xs-12 col-md-1 serif">
          <div class="md-icon-button remove-product" @click="$emit('remove', product.item.id)">
            <md-icon>
              delete_outline
            </md-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Renderer from '../Questionnaire/Offers/ImageRender'
export default {
  components: {
    Renderer
  },
  props: {
    product: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>
.price-original {
  text-decoration: line-through;
}
.blend {
  mix-blend-mode: multiply;
}
</style>
