<template>
  <div class="pt20">
    <div class="row pl20">
      <div class="col-xs-1 col-sm-2 col-md-1">
        <div
          class="number-circle lh35 cl-white brdr-circle align-center weight-700"
          :class="{ 'bg-cl-th-accent' : isActive || isFilled, 'bg-cl-tertiary' : !isFilled && !isActive }"
        >
          2
        </div>
      </div>
      <div class="col-xs-11 col-sm-9 col-md-11">
        <div class="row mb15">
          <div class="col-xs-12 col-md-7" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <h3 class="m0 mb5">
              Shipping
            </h3>
          </div>
          <div class="col-xs-12 col-md-5 pr30">
            <div class="lh30 flex end-lg" v-if="isFilled && !isActive">
              <a href="#" class="cl-tertiary flex" @click.prevent="edit">
                <span class="pr5">
                  Edit shipping
                </span>
                <i class="material-icons cl-tertiary">edit</i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl20" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-11 col-sm-9 col-md-10">
        <div class="row">
          <base-checkbox
            v-if="currentUser && hasShippingDetails()"
            class="col-xs-12 mb25"
            id="shipToMyAddressCheckbox"
            @click="useMyAddress"
            v-model="shipToMyAddress"
          >
            Ship to my default address
          </base-checkbox>

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="first-name"
            :placeholder="'First name *'"
            v-model.trim="firstName"
            @blur="$v.shipping.firstName.$touch()"
            autocomplete="given-name"
            :validations="[
              {
                condition: $v.shipping.firstName.$error && !$v.shipping.firstName.required,
                text: 'Field is required'
              },
              {
                condition: !$v.shipping.firstName.minLength,
                text: 'Name must have at least 3 letters.'
              }
            ]"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="last-name"
            :placeholder="'Last name *'"
            v-model.trim="lastName"
            @blur="$v.shipping.lastName.$touch()"
            autocomplete="family-name"
            :validation="{
              condition: $v.shipping.lastName.$error && !$v.shipping.lastName.required,
              text: 'Field is required'
            }"
          />

          <base-input
            class="col-xs-12 mb25"
            type="text"
            name="street-address"
            :placeholder="'Street name *'"
            v-model.trim="streetAddress"
            @blur="$v.shipping.streetAddress.$touch()"
            autocomplete="address-line1"
            :validation="{
              condition: $v.shipping.streetAddress.$error && !$v.shipping.streetAddress.required,
              text: 'Field is required'
            }"
          />

          <base-input
            class="col-xs-12 mb25"
            type="text"
            name="apartment-number"
            :placeholder="'House/Apartment number *'"
            v-model.trim="apartmentNumber"
            autocomplete="address-line2"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="city"
            :placeholder="'City *'"
            v-model.trim="city"
            @blur="$v.shipping.city.$touch()"
            autocomplete="address-level2"
            :validation="{
              condition: $v.shipping.city.$error && !$v.shipping.city.required,
              text: 'Field is required'
            }"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="state"
            :placeholder="'State / Province'"
            v-model.trim="state"
            autocomplete="address-level1"
          />

          <base-input
            class="col-xs-12 col-sm-6 mb25"
            type="text"
            name="zip-code"
            :placeholder="'Zip code *'"
            v-model.trim="zipCode"
            @blur="$v.shipping.zipCode.$touch()"
            autocomplete="postal-code"
            :validations="[
              {
                condition: $v.shipping.zipCode.$error && !$v.shipping.zipCode.required,
                text: 'Field is required'
              },
              {
                condition: !$v.shipping.zipCode.minLength,
                text: 'Name must have at least 3 letters.'
              }
            ]"
          />

          <base-select
            class="col-xs-12 col-sm-6 mb25"
            name="countries"
            :options="countryOptions"
            :selected="country"
            :placeholder="'Country *'"
            :validations="[
              {
                condition: $v.shipping.country.$error && !$v.shipping.country.required,
                text: 'Field is required'
              }
            ]"
            v-model="country"
            autocomplete="country-name"
            @blur="$v.shipping.country.$touch()"
            @change="$v.shipping.country.$touch(); changeCountry();"
          />

          <base-input
            class="col-xs-12 mb25"
            type="text"
            name="phone-number"
            :placeholder="'Phone Number'"
            v-model.trim="phoneNumber"
            autocomplete="tel"
            :validation="{
              condition: $v.shipping.phoneNumber.$error && !$v.shipping.phoneNumber.required,
              text: 'Field is required'
            }"
            @blur="$v.shipping.phoneNumber.$touch()"
            @input="$v.shipping.phoneNumber.$touch();"
          />

          <h4 class="col-xs-12">
            Shipping method
          </h4>
          <div v-for="(method, index) in shippingMethods" :key="index" class="col-md-12">
            <label class="radioStyled"> {{ method.method_title }} |
              <span v-if="method.amount">{{ method.amount | price }}</span>
              <span v-if="!method.amount">Free</span>
              <input
                type="radio"
                :value="method.method_code"
                name="shipping-method"
                v-model="shippingMethod"
                @change="$v.shipping.shippingMethod.$touch(); changeShippingMethod();"
              >
              <span class="checkmark" />
            </label>
          </div>
          <span class="validation-error" v-if="$v.shipping.shippingMethod.$error && !$v.shipping.shippingMethod.required">
            Field is required
          </span>
        </div>
      </div>
    </div>
    <div class="row" v-if="isActive">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11">
        <div class="row">
          <div class="col-xs-12 col-md-8 my30 px20">
            <button-full
              data-testid="shippingSubmit"
              @click.native="sendDataToCheckout"
              :disabled="$v.shipping.$invalid"
            >
              Continue to payment
            </button-full>
          </div>
        </div>
      </div>
    </div>
    <div class="row pl20" v-if="!isActive && isFilled">
      <div class="hidden-xs col-sm-2 col-md-1" />
      <div class="col-xs-12 col-sm-9 col-md-11">
        <div class="row fs16 mb35">
          <div class="col-xs-12 h4" data-testid="shippingAddressSummary">
            <p>
              {{ shipping.firstName }} {{ shipping.lastName }}
            </p>
            <p>
              {{ shipping.streetAddress }} {{ shipping.apartmentNumber }}
            </p>
            <p>
              {{ shipping.city }} {{ shipping.zipCode }}
            </p>
            <p>
              <span v-if="shipping.state">{{ shipping.state }}, </span>
              <span>{{ getCountryName() }}</span>
            </p>
            <div v-if="shipping.phoneNumber">
              <span class="pr15">{{ shipping.phoneNumber }}</span>
              <tooltip>Phone number may be needed by carrier</tooltip>
            </div>
            <div class="col-xs-12">
              <h4>
                Shipping method
              </h4>
            </div>
            <div class="col-md-6 mb15">
              <label class="radioStyled"> {{ getShippingMethod().method_title }} | {{ getShippingMethod().amount | price }}
                <input type="radio" value="" checked disabled name="chosen-shipping-method">
                <span class="checkmark" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import Countries from '../../../resource/i18n/countries.json'
// import { Shipping } from '@/core/modules/checkout/components/Shipping'

import Tooltip from '@/components/Tooltip'
import { createHelpers } from 'vuex-map-fields'

// `fooModule` is the name of the Vuex module.
const { mapFields } = createHelpers({
  getterType: 'cart/getField',
  mutationType: 'cart/updateField'
})

export default {
  components: {
    Tooltip
  },
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isFilled: false,
      countries: Countries,
      shipToMyAddress: false,
      myAddressDetails: {
        firstname: '',
        lastname: '',
        country: '',
        region: '',
        city: '',
        street: ['', ''],
        postcode: '',
        telephone: ''
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$off('checkout-after-shippingset', this.onAfterShippingSet)
  },
  beforeMount () {
    this.$bus.$on('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$on('checkout-after-shippingset', this.onAfterShippingSet)
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    ...mapState('cart', ['shippingMethods']),
    ...mapFields([
      'shippingDetails.firstName',
      'shippingDetails.lastName',
      'shippingDetails.streetAddress',
      'shippingDetails.apartmentNumber',
      'shippingDetails.city',
      'shippingDetails.zipCode',
      'shippingDetails.state',
      'shippingDetails.country',
      'shippingDetails.phoneNumber',
      'shippingDetails.shippingMethod'
    ]),
    shipping: {
      get () {
        return this.$store.state.cart.shippingDetails
      },
      set (val) {
        this.$store.commit('cart/setShippingDetails', val)
      }
    },
    checkoutShippingDetails () {
      return this.$store.state.cart.shippingDetails
    },
    paymentMethod () {
      return this.$store.state.cart.paymentMethods
    },
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    }
  },
  validations: {
    shipping: {
      firstName: {
        required,
        minLength: minLength(3)
      },
      lastName: {
        required
      },
      country: {
        required
      },
      streetAddress: {
        required
      },
      shippingMethod: {
        required
      },
      zipCode: {
        required,
        minLength: minLength(3)
      },
      city: {
        required
      },
      phoneNumber: {
        required
      }
    }
  },
  methods: {
    onAfterShippingSet (receivedData) {
      this.shipping = receivedData
      this.isFilled = true
    },
    onAfterPersonalDetails (receivedData) {
      if (!this.isFilled) {
        this.firstName = receivedData.firstName
        this.lastName = receivedData.lastName
      }
    },
    sendDataToCheckout () {
      this.$bus.$emit('checkout-after-shippingDetails', this.shipping, this.$v)
      this.isFilled = true
    },
    edit () {
      if (this.isFilled) {
        this.$bus.$emit('checkout-before-edit', 'shipping')
        this.isFilled = false
      }
    },
    hasShippingDetails () {
      if (this.currentUser) {
        if (this.currentUser.hasOwnProperty('addresses') && this.currentUser.addresses.find(a => a.default_shipping)) {
          this.myAddressDetails = this.currentUser.addresses.find(a => a.default_shipping)
          return true
        }
      }
      return false
    },
    useMyAddress () {
      this.shipToMyAddress = !this.shipToMyAddress
      if (this.shipToMyAddress) {
        this.firstName = this.myAddressDetails.firstname
        this.lastName = this.myAddressDetails.lastname
        this.country = this.myAddressDetails.country_id
        this.state = this.myAddressDetails.region.region ? this.myAddressDetails.region.region : ''
        this.city = this.myAddressDetails.city
        this.streetAddress = this.myAddressDetails.street[0]
        this.apartmentNumber = this.myAddressDetails.street[1]
        this.zipCode = this.myAddressDetails.postcode
        this.phoneNumber = this.myAddressDetails.telephone
        this.shippingMethod = this.checkoutShippingDetails.shippingMethod
        this.shippingCarrier = this.checkoutShippingDetails.shippingCarrier
      } else {
        this.shipping = this.checkoutShippingDetails
      }
      this.changeCountry()
    },
    getShippingMethod () {
      for (let i = 0; i < this.shippingMethods.length; i++) {
        if (this.shippingMethods[i].method_code === this.shipping.shippingMethod) {
          return {
            method_title: this.shippingMethods[i].method_title,
            amount: this.shippingMethods[i].amount
          }
        }
      }
      return {
        method_title: '',
        amount: ''
      }
    },
    getCountryName () {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].code === this.shipping.country) {
          return this.countries[i].name
        }
      }
      return ''
    },
    changeCountry () {
      this.$bus.$emit('checkout-before-shippingMethods', this.country)
    },
    getCurrentShippingMethod () {
      let shippingCode = this.shipping.shippingMethod
      let currentMethod = this.shippingMethods ? this.shippingMethods.find(item => item.method_code === shippingCode) : {}
      return currentMethod
    },
    changeShippingMethod () {
      let currentShippingMethod = this.getCurrentShippingMethod()
      if (currentShippingMethod) {
        this.$bus.$emit('checkout-after-shippingMethodChanged', {
          country: this.shipping.country,
          method_code: currentShippingMethod.method_code,
          carrier_code: currentShippingMethod.carrier_code,
          payment_method: this.paymentMethod[0].code
        })
      }
    },
    notInMethods (method) {
      let availableMethods = this.shippingMethods
      if (availableMethods.find(item => item.method_code === method)) {
        return false
      }
      return true
    }
  }
}
</script>
