<template>
  <div>
    <header class="thank-you-title bg-cl-secondary py35 pl20">
      <div class="container">
        <breadcrumbs
          :routes="[{name: 'Homepage', route_link: '/'}]"
          :active-route="'Order confirmation'"
        />
        <h2 class="category-title">
          Order confirmation
        </h2>
      </div>
    </header>
    <div class="thank-you-content align-justify py40 pl20">
      <div class="container">
        <div class="row">
          <div class="col-md-6 pl20 pr20">
            <h3 v-if="isOnline">
              Your purchase
            </h3>
            <!--You can check status of your order by using our <b>delivery status</b> feature.-->
            <p v-if="isOnline" v-html="'You have successfully placed the order. You will receive an order confirmation e-mail with details of your order and a link to track its progress.'" />
            <p v-if="isOnline">
              E-mail us at <b><a href="mailto:help@couturme.com">help@couturme.com</a></b> with any questions, suggestions how we could improve products or shopping experience
            </p>
            <h4 v-if="isOffline">
              You are offline
            </h4>
            <p v-if="isOffline && !isNotificationSupported">
              To finish the order just come back to our store while online. Your order will be sent to the server as soon as you come back here while online and then confirmed regarding the stock quantities of selected items
            </p>
            <p v-if="isOffline && isNotificationSupported && !isPermissionGranted">
              {{ "You can allow us to remind you about the order via push notification after coming back online. You'll only need to click on it to confirm." }}
            </p>
            <p v-if="isOffline && isNotificationSupported && isPermissionGranted">
              <strong>You will receive Push notification after coming back online. You can confirm the order by clicking on it</strong>
            </p>
            <!--<p v-if="!isPermissionGranted && isNotificationSupported">
              <button-outline color="dark" @click.native="requestNotificationPermission()">
                Allow notification about the order
              </button-outline>
            </p>-->
            <div id="thank-you-extensions" />
            <h4>
              Your Account
            </h4>
            <p>
              You can log to your account using e-mail and password defined earlier. On your account you can
              <b><router-link to="/my-account/#edit">edit your profile data,</router-link></b> check <b>
                <router-link to="/my-account/orders">history of transactions,</router-link></b> edit
              <b><router-link to="/my-account/newsletter">subscription to newsletter.</router-link></b>
            </p>
          </div>
          <div class="col-md-6 bg-cl-secondary thank-you-improvment">
            <h3>
              What we can improve?
            </h3>
            <p class="mb25">
              Your feedback is important fo us. Let us know what we could improve.
            </p>
            <form @submit.prevent="sendFeedback">
              <base-textarea
                class="mb25"
                type="text"
                name="body"
                v-model="feedback"
                :placeholder="'Type your opinion'"
                :autofocus="true"
              />
              <button-outline color="dark">
                Give a feedback
              </button-outline>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs'
import { VueOfflineMixin } from 'vue-offline'

export default {
  name: 'ThankYouPage',
  mixins: [ VueOfflineMixin ],
  data () {
    return {
      feedback: ''
    }
  },
  computed: {
    lastOrderConfirmation () {
      return this.$store.state.order.last_order_confirmation ? this.$store.state.order.last_order_confirmation.confirmation : {}
    },
    isNotificationSupported () {
      if (Vue.prototype.$isServer || !('Notification' in window)) return false
      return 'Notification' in window
    },
    isPermissionGranted () {
      if (Vue.prototype.$isServer || !('Notification' in window)) return false
      return Notification.permission === 'granted'
    },
    cartPersonalEmailAddress () {
      return this.$store.state.order.lastOrderData.shippingAddress.email
    },
    cartPersonalName () {
      return [this.$store.state.order.lastOrderData.shippingAddress.firstname, this.$store.state.order.lastOrderData.shippingAddress.lastname].join(' ')
    },
    mailerElements () {
      return this.$store.state.config.mailer.contactAddress
    }
  },
  methods: {
    requestNotificationPermission () {
      if (Vue.prototype.$isServer) return false
      if ('Notification' in window && Notification.permission !== 'granted') {
        Notification.requestPermission()
      }
    },
    sendFeedback () {
      this.$store.dispatch('user/sendFeedback',
        {
          from: this.cartPersonalEmailAddress,
          name: this.cartPersonalName,
          subject: 'What we can improve?',
          emailText: this.feedback
        },
        this.onSuccess,
        this.onFailure
      ).then(this.onSuccess).catch(this.onFailure)
    },
    onSuccess (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message,
        action1: { label: 'OK' }
      })
    },
    onFailure (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message,
        action1: { label: 'OK' }
      })
    }
  },
  destroyed () {
    this.$store.dispatch('cart/setThankYouPage', false)
  },
  components: {
    Breadcrumbs
  }
}
</script>

<style lang="scss">
  .thank-you-content {
    padding-left: 0;

    p {
      line-height: 25px
    }

    @media (min-width: 64em) {
      h4 {
        font-size: 24px;
      }
    }
  }
  .thank-you-improvment {
    padding: 0 20px 15px;

    @media (min-width: 64em) {
      padding: 0 40px 10px;
    }

    textarea {
      min-height: 100px;
    }
  }
</style>
