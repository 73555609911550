<template>
  <div id="checkout">
    <div class="container">
      <div class="row" v-show="!orderPlaced">
        <div class="col-sm-7 col-xs-12 pb70">
          <div class="checkout-title py5 px20">
            <h1>
              Checkout
            </h1>
          </div>
          <div v-if="isCartEmpty" class=" py5 px20">
            <h3>
              Your shopping cart is empty
            </h3>
            <router-link :to="backLink">Continue shopping</router-link>
          </div>
          <div v-if="!isCartEmpty">
            <personal-details
              class="line relative"
              :is-active="activeSection.personalDetails"
              :focused-field="focusedField"
            />
            <shipping class="line relative" :is-active="activeSection.shipping" />
            <payment class="line relative" :is-active="activeSection.payment" />
            <order-review class="line relative" :is-active="activeSection.orderReview" />
            <div id="custom-steps" />
          </div>
        </div>
        <div class="hidden-xs col-sm-5 bg-cl-secondary" v-if="!isCartEmpty">
          <cart-summary />
        </div>
      </div>
    </div>
    <thank-you-page v-show="orderPlaced" />
    <pay-modal />
  </div>
</template>

<script>
import PersonalDetails from '../components/blocks/Checkout/PersonalDetails'
import Shipping from '../components/blocks/Checkout/Shipping'
import Payment from '../components/blocks/Checkout/Payment'
import OrderReview from '../components/blocks/Checkout/OrderReview'
import CartSummary from '../components/blocks/Checkout/CartSummary'
import ThankYouPage from '../components/blocks/Checkout/ThankYouPage'
import PayModal from '../components/blocks/Checkout/PayModal'
import UUID from 'uuid/v4'
import { trackEvent } from '../utils/analytics'

export default {
  components: {
    PersonalDetails,
    Shipping,
    Payment,
    OrderReview,
    CartSummary,
    ThankYouPage,
    PayModal
  },
  beforeMount () {
    // TO-DO: Use one event with name as apram
    this.$bus.$on('cart-after-update', this.onCartAfterUpdate)
    this.$bus.$on('cart-after-delete', this.onCartAfterUpdate)
    this.$bus.$on('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$on('checkout-after-shippingDetails', this.onAfterShippingDetails)
    this.$bus.$on('checkout-after-paymentDetails', this.onAfterPaymentDetails)
    this.$bus.$on('checkout-after-cartSummary', this.onAfterCartSummary)
    this.$bus.$on('checkout-before-placeOrder', this.onBeforePlaceOrder)
    this.$bus.$on('checkout-do-placeOrder', this.onDoPlaceOrder)
    this.$bus.$on('checkout-before-edit', this.onBeforeEdit)
    this.$bus.$on('order-after-placed', this.onAfterPlaceOrder)
    this.$bus.$on('checkout-before-shippingMethods', this.onBeforeShippingMethods)
    this.$bus.$on('checkout-after-shippingMethodChanged', this.onAfterShippingMethodChanged)
    this.$bus.$on('checkout-after-validationError', this.focusField)
    let country = this.$store.state.cart.shippingDetails.country
    this.$bus.$emit('checkout-before-shippingMethods', country)
  },
  beforeDestroy () {
    this.$bus.$off('cart-after-update', this.onCartAfterUpdate)
    this.$bus.$off('cart-after-delete', this.onCartAfterUpdate)
    this.$bus.$off('checkout-after-personalDetails', this.onAfterPersonalDetails)
    this.$bus.$off('checkout-after-shippingDetails', this.onAfterShippingDetails)
    this.$bus.$off('checkout-after-paymentDetails', this.onAfterPaymentDetails)
    this.$bus.$off('checkout-after-cartSummary', this.onAfterCartSummary)
    this.$bus.$off('checkout-before-placeOrder') // this is intentional exception as the payment methods are dynamically binding to the before-placeOrder event
    this.$bus.$off('checkout-do-placeOrder', this.onDoPlaceOrder)
    this.$bus.$off('checkout-before-edit', this.onBeforeEdit)
    this.$bus.$off('order-after-placed', this.onAfterPlaceOrder)
    this.$bus.$off('checkout-before-shippingMethods', this.onBeforeShippingMethods)
    this.$bus.$off('checkout-after-shippingMethodChanged', this.onAfterShippingMethodChanged)
    this.$bus.$off('checkout-after-validationError', this.focusField)
  },
  watch: {
    '$route': 'activateHashSection',
    'OnlineOnly': 'onNetworkStatusCheck'
  },
  data () {
    return {
      stockCheckCompleted: true,
      stockCheckOK: true,
      orderPlaced: false,
      confirmation: null, // order confirmation from server
      activeSection: {
        personalDetails: true,
        shipping: false,
        payment: false,
        orderReview: false
      },
      order: {},
      personalDetails: {},
      shipping: {},
      shippingMethod: {},
      payment: {},
      orderReview: {},
      cartSummary: {},
      validationResults: {
        personalDetails: { $invalid: true },
        shipping: { $invalid: true },
        payment: { $invalid: true }
      },
      userId: null,
      focusedField: null
    }
  },
  computed: {
    isCartEmpty () {
      return !this.$store.state.cart.cart || !this.$store.state.cart.cart.length
    },
    backLink () {
      return this.$store.getters['user/isLoggedIn'] && this.$store.getters['questionnaire/canCreateOffers'] ? '/questionnaire/offers' : '/questionnaire'
    }
  },
  methods: {
    onCartAfterUpdate (payload) {
      if (this.$store.state.cart.cart.length === 0) {
        this.notifyEmptyCart()
        this.$router.push('/')
      }
    },
    onAfterShippingMethodChanged (payload) {
      this.$store.dispatch('cart/refreshTotals', payload)
      this.shippingMethod = payload
    },
    onBeforeShippingMethods (country) {
      // this.$store.dispatch('cart/getShippingMethods', {
      //   country_id: country
      // }).then(() => {
      //   this.$store.dispatch('cart/refreshTotals')
      //   this.$forceUpdate()
      // })
    },
    onAfterPlaceOrder (payload) {
      this.confirmation = payload.confirmation
      this.orderPlaced = true
      this.$store.dispatch('cart/setThankYouPage', true)
      this.$modal.hide('payment')
      trackEvent('user-action', 'order-paid')
    },
    onBeforeEdit (section) {
      this.activateSection(section)
    },
    onBeforePlaceOrder (userId) {
      if (userId) {
        this.userId = userId.toString()
      }
    },
    onAfterCartSummary (receivedData) {
      this.cartSummary = receivedData
      trackEvent('user-action', 'checkout-cart-summary')
    },
    onDoPlaceOrder (additionalPayload) {
      if (this.$store.state.cart.cart.length === 0) {
        this.notifyEmptyCart()
        this.$router.push('/')
      } else {
        this.payment.paymentMethodAdditional = additionalPayload
        this.placeOrder()
      }
    },
    onAfterPaymentDetails (receivedData, validationResult) {
      this.payment = receivedData
      this.validationResults.payment = validationResult
      this.activateSection('orderReview')
      this.savePaymentDetails()
      trackEvent('user-action', 'checkout-payment-details')
    },
    onAfterShippingDetails (receivedData, validationResult) {
      this.shipping = receivedData
      this.validationResults.shipping = validationResult
      this.activateSection('payment')
      this.saveShippingDetails()
      trackEvent('user-action', 'checkout-shipping-details')
    },
    onAfterPersonalDetails (receivedData, validationResult) {
      this.personalDetails = receivedData
      this.validationResults.personalDetails = validationResult
      this.activateSection('shipping')
      this.savePersonalDetails()
      this.focusedField = null
      trackEvent('user-action', 'checkout-personal-details')
    },
    onNetworkStatusCheck (isOnline) {
      this.checkConnection(isOnline)
    },
    checkStocks () {
      let isValid = true
      for (let child of this.$children) {
        if (child.hasOwnProperty('$v')) {
          if (child.$v.$invalid) {
            // Check if child component is Personal Details.
            // If so, then ignore validation of account creation fields.
            if (child.$v.hasOwnProperty('personalDetails')) {
              if (child.$v.personalDetails.$invalid) {
                isValid = false
                break
              }
            } else {
              isValid = false
              break
            }
          }
        }
      }

      if (typeof navigator !== 'undefined' && navigator.onLine) {
        if (this.stockCheckCompleted) {
          if (!this.stockCheckOK) {
            isValid = false
            this.notifyNotAvailable()
          }
        } else {
          this.notifyStockCheck()
          isValid = false
        }
      }
      return isValid
    },
    activateHashSection () {
      if (typeof window !== 'undefined') {
        var urlStep = window.location.hash.replace('#', '')
        if (this.activeSection.hasOwnProperty(urlStep) && this.activeSection[urlStep] === false) {
          this.activateSection(urlStep)
        } else if (urlStep === '') {
          this.activateSection('personalDetails')
        }
      }
    },
    checkConnection (isOnline) {
      if (!isOnline) {
        this.notifyNoConnection()
      }
    },
    activateSection (sectionToActivate) {
      for (let section in this.activeSection) {
        this.activeSection[section] = false
      }
      this.activeSection[sectionToActivate] = true
      if (typeof window !== 'undefined') window.location.href = window.location.origin + window.location.pathname + '#' + sectionToActivate
    },
    // This method checks if there exists a mapping of chosen payment method to one of Magento's payment methods.
    getPaymentMethod () {
      return this.payment.paymentMethod
    },
    prepareOrder () {
      this.order = {
        order_id: UUID(),
        user_id: this.$store.state.user.current ? this.$store.state.user.current._id.toString() : (this.userId ? this.userId : ''),
        cart_id: this.$store.getters['cart/getCartUuid'],
        products: this.$store.getters['cart/getCart'],
        addressInformation: {
          shippingAddress: {
            region: this.shipping.state,
            region_id: this.shipping.region_id ? this.shipping.region_id : 0,
            country_id: this.shipping.country,
            street: [this.shipping.streetAddress, this.shipping.apartmentNumber],
            company: 'NA', // TODO: Fix me! https://github.com/DivanteLtd/vue-storefront/issues/224
            telephone: this.shipping.phoneNumber,
            postcode: this.shipping.zipCode,
            city: this.shipping.city,
            firstname: this.shipping.firstName,
            lastname: this.shipping.lastName,
            email: this.personalDetails.emailAddress,
            region_code: this.shipping.region_code ? this.shipping.region_code : ''
          },
          billingAddress: {
            region: this.payment.state,
            region_id: this.payment.region_id ? this.payment.region_id : 0,
            country_id: this.payment.country,
            street: [this.payment.streetAddress, this.payment.apartmentNumber],
            company: this.payment.company,
            telephone: this.payment.phoneNumber,
            postcode: this.payment.zipCode,
            city: this.payment.city,
            firstname: this.payment.firstName,
            lastname: this.payment.lastName,
            email: this.personalDetails.emailAddress,
            region_code: this.payment.region_code ? this.payment.region_code : '',
            vat_id: this.payment.taxId
          },
          shipping_method_code: this.shippingMethod.method_code ? this.shippingMethod.method_code : this.shipping.shippingMethod,
          shipping_carrier_code: this.shippingMethod.carrier_code ? this.shippingMethod.carrier_code : this.shipping.shippingCarrier,
          payment_method_code: this.getPaymentMethod(),
          payment_method_additional: this.payment.paymentMethodAdditional,
          shippingExtraFields: this.shipping.extraFields
        }
      }
      this.$store.dispatch('order/setLastOrderData', this.order)
      return this.order
    },
    placeOrder () {
      this.checkConnection({ online: typeof navigator !== 'undefined' ? navigator.onLine : true })
      if (this.checkStocks()) {
        this.$store.dispatch('cart/placeOrder', { order: this.prepareOrder() })
        trackEvent('user-action', 'order-prepare')
      } else {
        this.notifyNotAvailable()
      }
    },
    savePersonalDetails () {
      this.$store.dispatch('cart/savePersonalDetails', this.personalDetails)
    },
    saveShippingDetails () {
      this.$store.dispatch('cart/saveShippingDetails', this.shipping)
    },
    savePaymentDetails () {
      this.$store.dispatch('cart/savePaymentDetails', this.payment)
    },
    focusField (fieldName) {
      if (fieldName === 'password') {
        window.scrollTo(0, 0)
        this.activateSection('personalDetails')
        this.focusedField = fieldName
      }
      if (fieldName === 'email-address') {
        window.scrollTo(0, 0)
        this.activateSection('personalDetails')
        this.focusedField = fieldName
      }
    },
    notifyEmptyCart () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: 'Shopping cart is empty. Please add some products before entering Checkout',
        action1: { label: 'OK' }
      })
    },
    notifyOutStock (chp) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: chp.name + ' is out of the stock!',
        action1: { label: 'OK' }
      })
    },
    notifyNotAvailable () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: 'Some of the ordered products are not available!',
        action1: { label: 'OK' }
      })
    },
    notifyStockCheck () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: 'Stock check in progress, please wait while available stock quantities are checked',
        action1: { label: 'OK' }
      })
    },
    notifyNoConnection () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: 'There is no Internet connection. You can still place your order. We will notify you if any of ordered products is not available because we cannot check it right now.',
        action1: { label: 'OK' }
      })
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || 'Checkout',
      meta: this.$route.meta.description ? [{ vmid: 'description', description: this.$route.meta.description }] : []
    }
  }
}
</script>

<style lang="scss">
  @import '../css/base/text';
  $bg-secondary: color(secondary, $colors-background);
  $color-tertiary: color(tertiary);
  $color-secondary: color(secondary);
  $color-error: color(error);
  $color-white: color(white);
  $color-black: color(black);

  #checkout {
    .number-circle {
      width: 35px;
      height: 35px;

      @media (max-width: 768px) {
        width: 25px;
        height: 25px;
        line-height: 25px;
      }
    }
    .radioStyled {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 16px;
      line-height: 30px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid $bg-secondary;

        &:after {
          content: "";
          position: absolute;
          display: none;
          top: 3px;
          left: 3px;
          width: 19px;
          height: 19px;
          border-radius: 50%;
          background: $color-secondary;
        }
      }

      input:checked ~ .checkmark:after {
        display: block;
      }
    }
  }

  .line {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 37px;
      z-index: -1;
      width: 1px;
      height: 100%;
      background-color: $bg-secondary;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .checkout-title {
    @media (max-width: 767px) {
      background-color: $bg-secondary;
      margin-bottom: 25px;

      h1 {
        font-size: 36px;
      }
    }
  }
</style>
